import React from "react";
import Iterator from "../../utils/Iterator";
import CartRow from "./CartRow";

function TableCart({ handleUpdateQuantity, handleRemoveItem, ...props }) {
  return (
    <div className="flex flex-col gap-5">
      <Iterator
        data={props.data}
        render={(e, i) => (
          <CartRow
            key={i}
            element={e}
            handleUpdateQuantity={handleUpdateQuantity}
            handleRemoveItem={handleRemoveItem}
          />
        )}
      />
    </div>
  );
}

export default TableCart;
