export const formatDate = (isoDate) => {
  const lang = localStorage.getItem("Lang") || "en";
  const date = new Date(isoDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // لعرض الوقت بصيغة 24 ساعة
  };
  return date.toLocaleString(lang, options);
};
