import React from "react";
import { formatDate } from "../../utils/formatDate ";
import { useTranslation } from "react-i18next";

function OrderElement({ onClick, order, name }) {
  const { id, price, note, created_at } = order;
  const formattedDate = formatDate(created_at);
  const { t } = useTranslation();
  // const status = t(`gloabl.${order.status}`);
  const status = t(`gloabl.canselled`);
  return (
    <div
      className="border p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer hover:shadow-lg"
      onClick={() => onClick(order)}
    >
      <div>
        <h3 className="text-lg font-bold">
          {t("gloabl.order")} #{id}
        </h3>
        <p className="text-sm">
          <span className="font-bold">{t("gloabl.value")} : </span> {price}
          {name == "name_en" ? "AED" : "دإ"}
        </p>
        <p className="text-sm  truncate">
          <span className="font-bold"> {t("gloabl.Notes")} : </span>{" "}
          {note || t("gloabl.noNotes")}
        </p>
        <p className="text-sm">
          <span className="font-bold">{t("gloabl.date")} : </span>
          {formattedDate}
        </p>
      </div>
      <span
        className={`px-3 py-1 text-sm rounded-full ${
          status === t(`gloabl.inProcess`)
            ? "bg-yellow-200 text-yellow-800"
            : status === t(`gloabl.reday`)
            ? "bg-green-200 text-green-800"
            : status === t(`gloabl.canselled`)
            ? "bg-red-200 text-red-800"
            : "bg-gray-200 text-gray-800"
        }`}
      >
        {status}
      </span>
    </div>
  );
}

export default OrderElement;
