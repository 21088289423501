import BranchInfo from "../Details/BranchInfo";
import MenusElemnts from "../MenusElements/MenusElemnts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import Visibility from "../utils/Visibility";
import SocialMedia from "../Details/SocialMedia";

export function BranchTabs(props) {
  const { t } = useTranslation();
  const { lang } = indexStore();
  const classNameTrigger = ` text-disable s w-full data-[state=active]:text-primary shadow-none text-lg 
  border-b-4  data-[state=active]:border-primary font-extrabold shadow-xs `;
  return (
    <Tabs
      dir={lang === "ar" ? "rtl" : "ltr"}
      defaultValue={"menu"}
      className={`flex flex-col gap-5 ${props.className}`}
    >
      <TabsList className="flex justify-around bg-transparent border-disable gap-4 ">
        <TabsTrigger className={`${classNameTrigger} border-disable`} value="menu">
          {t("gloabl.menus")}
        </TabsTrigger>
        <TabsTrigger className={`${classNameTrigger} border-disable`} value="info">
          {t("gloabl.info")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="menu">
        <MenusElemnts data={props.menus} onClickMenu={props.onClickMenu} />
      </TabsContent>
      <TabsContent value="info">
        <div className="flex flex-col">
          <BranchInfo branch={props.branch} />
          <Visibility visible={props.social && props.social.length !== 0}>
            <SocialMedia social={props.social} />
          </Visibility>
        </div>
      </TabsContent>
    </Tabs>
  );
}
