import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { GrOrderedList } from "react-icons/gr";
import { LuGlobe } from "react-icons/lu";
import { ShoppingCart } from "lucide-react";
import Visibility from "./utils/Visibility";
import { DarkModeTogle } from "../components/langSelect/DarkModeTogle";
import { useTranslation } from "react-i18next";
import { indexStore } from "../store/indexStore";
import { FooterCopy } from "./footer";
import { CalcQuantity } from "./utils/CartFunctions";
import { Sheet, SheetTrigger } from "./ui/sheet";
import LangSelect from "./langSelect/langSelect";
const NavBottom = (props) => {
  const { lang, changeLangBottomSheet } = indexStore();
  const { t } = useTranslation();
  const params = useParams();
  useEffect(() => {
    CalcQuantity(params.branch_id);
  }, [CalcQuantity]);
  const qantity = CalcQuantity(params.branch_id);
  const tokenOrder = localStorage.getItem("tokenOrder" + params.branch_id);

  const classNameNavElement =
    "flex flex-col gap-2  items-center  cursor-pointer ";
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-background flex flex-col gap-1 shadow-lg p-2 z-10">
      <nav
        className={`grid grid-cols-4 flex-1 justify-end  items-center text-onBackground `}
      >
        <Sheet>
          <SheetTrigger asChild>
        <div
          className={classNameNavElement}
          onClick={() => changeLangBottomSheet(true)}
        >
            <LuGlobe className="text-xl font-semibold" />
            <span className="text-sm font-semibold">
              {lang === "ar" ? "ع" : "EN"}
            </span>
            </div>
          </SheetTrigger>
          <LangSelect/>
        </Sheet>
        <DarkModeTogle />

        <Visibility visible={params.branch_id}>
          <Link
            className={classNameNavElement}
            to={`/${params.domain}/${params.branch_id}/cart`}
          >
            <div className="relative">
              <Visibility visible={qantity > 0}>
                <span className="absolute -top-2 text-xs -right-2 bg-primary  text-onPrimary p-1 rounded-full">
                  {qantity}
                </span>
              </Visibility>
              <ShoppingCart className={`text-xl font-semibold `} />
            </div>

            <span className="text-sm font-semibold">{t("gloabl.Cart")}</span>
          </Link>
          <Visibility visible={tokenOrder && params.branch_id}>
            <Link
              className={classNameNavElement}
              to={`/${params.domain}/${params.branch_id}/orders`}
            >
              <GrOrderedList className="text-xl font-semibold" />
              <span className="text-sm font-semibold">
                {t("gloabl.orders")}
              </span>
            </Link>
          </Visibility>
        </Visibility>
      </nav>
      <FooterCopy />
    </div>
  );
};

export default NavBottom;
