import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMG_URl } from "../../common/img_url";
import { Header } from "../../components/header";
import { indexStore } from "../../store/indexStore";
import { useTranslation } from "react-i18next";
import Visibility from "../../components/utils/Visibility";
import IsNotActive from "../../components/IsNotActive/IsNotActive";

import Iterator from "../../components/utils/Iterator";
import { Skeleton } from "../../components/ui/skeleton";
import { BranchTabs } from "../../components/Tabs/BranchTabs";
import NavBottom from "../../components/NavBottom";
function Branch(props) {
  const { t } = useTranslation();
  const {
    changeCategory,
    fetchMenusAction,
    menus,
    branch,
    restaurant,
    social,
    loading,
    description,
    fetchPriceOffersProducts,
  } = indexStore();
  const params = useParams();
  const paramsRequst = {
    domain: params.domain,
    branch_id: params.branch_id,
  };
  const naivgate = useNavigate();
  useEffect(() => {
    fetchMenusAction(paramsRequst).then(() => {
      fetchPriceOffersProducts(params.branch_id);
    });

    changeCategory(0);
  }, []);
  const onClickMenu = (e) => {
    naivgate(`${e}`);
  };
  const formattedDescription = branch[description]?.replace(/\n/g, "<br/>");

  return (
    <IsNotActive>
      <title>{paramsRequst.domain.toUpperCase()}</title>
      <Header noBack={true} />
      <Visibility visible={!loading}>
        <section
          className={`bg-background p-4 flex  flex-col gap-8 flex-grow text-onBackground `}
        >
          <div className="flex flex-row gap-8 ">
            <div className="w-36 h-36 p-4 rounded-md shadow-md">
              <img
                className=" w-full h-full aspect-square object-contain"
                src={`${IMG_URl}${restaurant.logo}`}
                alt=""
              />
            </div>
            <h6
              className="leading-6 capitalize text-base font-semibold"
              dangerouslySetInnerHTML={{ __html: formattedDescription }}
            ></h6>
          </div>
          <Visibility visible={menus.length !== 0}>
            <BranchTabs
              menus={menus}
              branch={branch}
              social={social}
              onClickMenu={onClickMenu}
            />
          </Visibility>
        </section>
      </Visibility>
      <Visibility visible={loading}>
        <RenderLoading />
      </Visibility>
    </IsNotActive>
  );
}

export default Branch;
const RenderLoading = () => {
  return (
    <div className="p-4 flex  flex-col gap-8 ">
      <div className="flex flex-row gap-8  ">
        <Skeleton className="w-36 aspect-square" />
        <div className="flex flex-col gap-4">
          <Skeleton className={"w-36 h-6"} />
          <Skeleton className={"w-36 h-6"} />
        </div>
      </div>

      <div className="flex w-full justify-around bg-transparent gap-4">
        <Skeleton className={"w-36 h-6"} />
        <Skeleton className={"w-36 h-6"} />
      </div>

      <div className={`grid grid-cols-2  md:grid-cols-3 gap-5 `}>
        <Iterator
          data={[1, 2, 3, 4]}
          render={() => (
            <div>
              <Skeleton className={` w-full h-36 rounded-lg  `} />
            </div>
          )}
        />
      </div>
    </div>
  );
};
