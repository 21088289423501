import React from "react";
import Visibility from "../utils/Visibility";
import { indexStore } from "../../store/indexStore";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdBlockFlipped } from "react-icons/md";
import imgL from "../../assets/TWH1-black.png";
import NavBottom from "../NavBottom";

function IsNotActive(props) {
  const { t } = useTranslation();
  const { style, lang, errorCode } = indexStore();
  const params = useParams();
  return (
    <div className="flex flex-col flex-grow">
      <Visibility visible={errorCode == 403}>
        <title>{params.domain.toUpperCase()}</title>
        <div className="flex flex-col items-center justify-center min-h-screen bg-white text-center p-6">
          <img
            src={imgL}
            alt="Logo"
            className="w-24 aspect-square object-contain"
          />
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            404 - Page Not Found
          </h1>
          <p className="text-lg text-gray-600 mb-4">
            Oops! We couldn’t find the page you were looking for. It might have
            been eaten!
          </p>
        </div>
      </Visibility>
      <Visibility visible={!errorCode}>
        {props.children} <NavBottom />
      </Visibility>
    </div>
  );
}

export default IsNotActive;
