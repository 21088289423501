import React, { useEffect, useState } from "react";
import { Header } from "../header";
import { Link, useParams } from "react-router-dom";
import TableCart from "./TableCart/TableCart";
import { useTranslation } from "react-i18next";
import {
  calculateTotal,
  getCartItems,
  removeFromCart,
  updateQuantity,
} from "../utils/CartFunctions";
import { ShoppingCart } from "lucide-react";
import Visibility from "../utils/Visibility";
import OptSend from "./OptSend";
import { indexStore } from "../../store/indexStore";
import { toast } from "react-toastify";
import { BrowseProducts, CheckoutComponent } from "./Tools";
import IsNotActive from "../IsNotActive/IsNotActive";

function Cart() {
  const params = useParams();
  const { style, getStyle, restaurant, fetchBranchesAction } = indexStore();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (Object.keys(style).length == 0) {
      getStyle(params);
    }
    if (restaurant) fetchBranchesAction({ domain: params.domain });
    loadCart();
  }, []);

  // تحميل العربة وتحديث الإجمالي
  const loadCart = () => {
    const items = getCartItems(params.branch_id);
    setCartItems(items);
    setTotal(calculateTotal(params.branch_id));
  };

  // تحديث الكمية
  const handleUpdateQuantity = (productId, newQuantity) => {
    updateQuantity(productId, newQuantity, params.branch_id);
    loadCart();
  };

  // حذف عنصر
  const handleRemoveItem = (productId) => {
    removeFromCart(productId, params.branch_id);
    loadCart();
  };

  const { t } = useTranslation();

  return (
    <IsNotActive>

    <div className="flex flex-col flex-grow bg-background">
      <title>{params.domain.toUpperCase()}</title>
      <Header />
      <Visibility visible={cartItems.length == 0}>
        <EmptyCart params={params} t={t} />
      </Visibility>
      <Visibility visible={cartItems.length > 0}>
        <div className="p-4 flex flex-col pb-20 gap-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-3xl font-bold text-onBackground">
              {t("gloabl.Cart")}
            </h3>
            <BrowseProducts params={params} t={t} />
          </div>
          <TableCart
            data={cartItems}
            handleUpdateQuantity={handleUpdateQuantity}
            handleRemoveItem={handleRemoveItem}
          />

          <CheckoutComponent
            cartItems={cartItems}
            total={total}
            t={t}
            params={params}
            loadCart={loadCart}
          />
        </div>
      </Visibility>
    </div>
    </IsNotActive>

  );
}

export default Cart;

const EmptyCart = ({ params, t }) => {
  return (
    <div className="flex flex-col gap-3 items-center justify-center h-full text-center p-8">
      <ShoppingCart className="w-24 h-24 text-gray-400" />
      <h2 className="text-2xl font-bold text-onBackground-600 mt-4">
        {" "}
        {t("gloabl.emptyCart")}
      </h2>
      <p className="text-onBackground-500 mt-2">
        {t("gloabl.emptyCartMessage")}
      </p>
      <BrowseProducts params={params} t={t} />
    </div>
  );
};
