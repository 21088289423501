import React, { useEffect, useState } from "react";
import { Header } from "../header";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { indexStore } from "../../store/indexStore";
import { BrowseProducts } from "./Tools";
import Iterator from "../utils/Iterator";
import OrderElement from "./OrderElement/OrderElement";
import OrderDetiles from "./OrderElement/OrderDetiles";
import Visibility from "../utils/Visibility";
import { Sheet, SheetTrigger } from "../ui/sheet";
import { Skeleton } from "../ui/skeleton";
import IsNotActive from "../IsNotActive/IsNotActive";

function Orders() {
  const params = useParams();
  const { t } = useTranslation();
  const {
    fetchOrdersAction,
    orders,
    style,
    getStyle,
    showOrderAction,
    restaurant,
    fetchBranchesAction,
    name,
    ordersLoading,
    orderLoading,
    order,
  } = indexStore();
  const tokenOrder = localStorage.getItem("tokenOrder" + params.branch_id);

  useEffect(() => {
    if (Object.keys(style).length == 0) {
      getStyle(params);
    }
    if (restaurant) fetchBranchesAction({ domain: params.domain });
    fetchOrdersAction({ token: tokenOrder });
  }, []);

  const handleOrderClick = (order) => {
    showOrderAction({ order_id: order.id, token: tokenOrder }).then((res) => {
      // setSelectedOrder(res);
    });
  };

  return (
    <IsNotActive>
      <div className="flex flex-col flex-grow bg-background">
        <title>{params.domain.toUpperCase()}</title>
        <Header />
        <div className="p-4 flex flex-col gap-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-3xl font-bold text-onBackground">
              {t("gloabl.orders")}
            </h3>
            <BrowseProducts params={params} t={t} />
          </div>
          <Sheet className="flex flex-col gap-4 pb-20">
            <Visibility visible={!ordersLoading}>
              <Iterator
                data={orders}
                render={(e, i) => (
                  <SheetTrigger asChild>
                    <OrderElement
                      name={name}
                      t={t}
                      params={params}
                      order={e}
                      index={i}
                      tokenOrder={tokenOrder}
                      onClick={(params) => handleOrderClick(params)}
                    />
                  </SheetTrigger>
                )}
              />
            </Visibility>

            <OrderDetiles
              order={order}
              t={t}
              params={params}
              name={name}
              loading={orderLoading}
            />
          </Sheet>
          <Visibility visible={ordersLoading}>
            <Iterator data={[1, 2, 3]} render={(e, i) => <OrdersLoading />} />
          </Visibility>
        </div>
      </div>
    </IsNotActive>
  );
}

export default Orders;
const OrdersLoading = () => (
  <div className="border p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer hover:shadow-lg">
    <div className="flex flex-col gap-2">
      <Skeleton className={"w-48 h-"} />
      <Skeleton className={"w-48 h-4"} />
      <Skeleton className={"w-48 h-4"} />
      <Skeleton className={"w-48 h-4"} />
    </div>
    <Skeleton className={"w-8 h-8"} />
  </div>
);
