import { useTranslation } from "react-i18next";
import imgL from "../assets/TWH1-black.png";

export const FooterCopy = () => {
  const { t } = useTranslation();

  return (
    <footer className="w-full bg-white  p-1 text-xs flex justify-center items-center  text-center text-black">
      <a
        href="https://www.twhmenu.com/"
        target="_blank"
        rel="noreferrer"
        className="flex gap-1 items-center justify-center"
      >
        {t("gloabl.ProducedBy")}
        <img width={50} height={30} src={imgL} alt="cl" />
      </a>
    </footer>
  );
};
