import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import RestaurantRoutes from "./components/RestaurantRoutes/RestaurantRoutes";
import ContactUs from "./components/MainPageWebsite/ContactUs/ContactUs";
import HomePage from "./components/MainPageWebsite/HomePage/HomePage";
import { indexStore } from "./store/indexStore";
import PrivacyPolicy from "./components/MainPageWebsite/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./components/MainPageWebsite/TermsOfService/TermsOfService";
import LangSelect from "./components/langSelect/langSelect";
import ChatComponent from "./ChatComponent";
import { Header } from "./components/header";
import { useTheme } from "next-themes";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { style } = indexStore();
  const {  theme } = useTheme();
  const [primary, setPrimary] = useState("#000");
  const [onPrimary, setOnPrimary] = useState("#fff");
  React.useEffect(() => {
    if (theme === "dark") {
      setPrimary(style && style.background ? style.background : "#000");
      setOnPrimary(style && style.onBackground ? style.onBackground : "#fff");
    } else {
      setPrimary(style && style.primary ? style.primary : "#000");
      setOnPrimary(style && style.onPrimary ? style.onPrimary : "#fff");
    }
  }, [theme, style]);
  return (
    <div
      style={{
        "--primary-color": primary,
        "--onPrimary-color": onPrimary,
      }}
    >
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/chat" Component={ChatComponent} />
        <Route path="/contact" Component={ContactUs} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
        <Route path="/terms-of-service" Component={TermsOfService} />
        <Route path="/*" Component={RestaurantRoutes} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;
