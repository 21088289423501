import { toast } from "react-toastify";

const createProductKey = (product) => {
  let key = `id:${product.id}`;

  if (product.option && product.option.length > 0) {
    product.option.forEach((opt) => {
      key += `|option:${opt.option.id}`;
      if (opt.subOption) {
        key += `-subOption:${opt.subOption.id}`;
      }
    });
  }
  if(product&&product.note) key+=`|note:${product.note}`
  

  return key;
};

export const AddToCart = ({ element, params, navigate, t }) => {
  const cart =
    JSON.parse(localStorage.getItem(`cart${params.branch_id}`)) || [];

  const newProductKey = createProductKey(element);
  const existingProductIndex = cart.findIndex(
    (item) => createProductKey(item) === newProductKey
  );

  if (existingProductIndex !== -1) {
    cart[existingProductIndex].amount += 1; // زيادة الكمية إذا كان المنتج موجودًا
  } else {
    cart.push({ ...element, amount: 1 });
  }

  localStorage.setItem(`cart${params.branch_id}`, JSON.stringify(cart));
  toast.success(
    <div className="flex gap-1 flex-col">
      <p>{t("gloabl.addedToCart")}</p>
      <p>{t("gloabl.clickToCart")}</p>
    </div>,
    {
      onClick: () => {
        navigate(`/${params.domain}/${params.branch_id}/cart`);
      },
    }
  );
};

export const updateQuantity = (product, newQuantity, branch_id) => {
  const cart = JSON.parse(localStorage.getItem(`cart${branch_id}`)) || [];

  const productKey = createProductKey(product);

  const updatedCart = cart.map((item) => {
    if (createProductKey(item) === productKey) {
      return { ...item, amount: newQuantity };
    }
    return item;
  });

  localStorage.setItem(`cart${branch_id}`, JSON.stringify(updatedCart));
};
export const removeFromCart = (product, branch_id) => {
  const cart = JSON.parse(localStorage.getItem(`cart${branch_id}`)) || [];

  const productKey = createProductKey(product);

  const updatedCart = cart.filter(
    (item) => createProductKey(item) !== productKey
  );

  localStorage.setItem(`cart${branch_id}`, JSON.stringify(updatedCart));
};

export const getCartItems = (branch_id) => {
  // استرجاع السلة من localStorage
  const cart = localStorage.getItem(`cart${branch_id}`);
  return cart ? JSON.parse(cart) : []; // إذا لم تكن السلة موجودة، تعيد مصفوفة فارغة
};

export const calculateTotal = (branch_id) => {
  // استرجاع السلة من localStorage
  const cart = localStorage.getItem(`cart${branch_id}`);
  const cartArray = cart ? JSON.parse(cart) : [];

  // حساب الإجمالي
  const total = cartArray.reduce((acc, item) => {
    // سعر المنتج الأساسي
    let itemTotal = item.price;

    // إضافة أسعار الخيارات المختارة
    if (item.option && item.option.length > 0) {
      item.option.forEach((opt) => {
        if (opt.subOption) {
          itemTotal += opt.subOption.price;
        }
      });
    }

    // مضاعفة المجموع حسب الكمية
    itemTotal *= item.amount;

    return acc + itemTotal;
  }, 0);

  return total;
};

export const CalcQuantity = ( branch_id) => {
  const qantity = JSON.parse(
    localStorage.getItem("cart" + branch_id)
  )?.reduce((acc, item) => acc + item.amount, 0);

  return qantity;
}
