import React from "react";
import { formatDate } from "../../utils/formatDate ";
import { useTranslation } from "react-i18next";
import Iterator from "../../utils/Iterator";
import { IMG_URl } from "../../../common/img_url";
import Visibility from "../../utils/Visibility";
import {
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../ui/sheet";
import { Skeleton } from "../../ui/skeleton";

const OrderDetiles = ({ order, t, name, loading }) => {
  const formattedDate = formatDate(order.created_at);

  return (
    <div>
      <SheetContent side={"bottom"} className="flex flex-col bg-background max-h-[60vh] overflow-y-auto z-50 ">
        <Visibility visible={loading}>
          <DetilesLoading />
        </Visibility>
        <Visibility visible={!loading}>
          <div className="flex flex-col gap-2">
            <SheetHeader>
              <SheetTitle>
                <h2 className="text-xl font-bold mb-4">
                  {t("gloabl.orderDetails")} #{order.id}
                </h2>
              </SheetTitle>
            </SheetHeader>
            <p>
              <strong className="font-bold">{t("gloabl.value")} : </strong>
              {order.price} {name == "name_en" ? "AED" : "دإ"}
            </p>
            <p>
              <strong>{t("gloabl.date")} : </strong> {formattedDate}
            </p>
            <p>
              <strong>{t("gloabl.status")} : </strong> {order.status}
            </p>
            <p>
              <strong>{t("gloabl.Notes")} : </strong>{" "}
              {order.note || t("gloabl.noNotes")}
            </p>

            <h3 className=" text-lg font-semibold">{t("gloabl.items")}:</h3>
            <div className="flex flex-col gap-4">
              <Iterator
                data={order.orderProdeuct}
                render={(item) => <ItemStructer item={item} name={name} />}
              />
            </div>
          </div>
        </Visibility>
      </SheetContent>
    </div>
  );
};

export default OrderDetiles;

const ItemStructer = ({ item, name }) => {

  
  return (
    <div className="flex items-center gap-4 flex-grow">
      <img
        src={`${IMG_URl}${item.product.image}`}
        alt="img"
        className="object-cover rounded-xl w-20 h-20 sm:w-24 sm:h-24"
      />

      <div className="flex flex-col gap-1 w-full">
        <h3 className="text-lg font-bold cursor-pointer">
          {item.product[name]}
        </h3>
        <Visibility
          visible={item.product.option && item.product.option.length > 0}
        >
          <div className="text-sm text-onBackground ">
            <Iterator
              data={item.product.option}
              render={(e, i) => renderOptions(e, i, name)}
            />
          </div>
        </Visibility>

        <div className="flex items-center text-onBackground gap-2 mt-1 text-sm font-semibold">
          <p>{item.price}</p>
          <span>x</span>
          <p>{item.amount}</p>
          <span>=</span>
          <p>
            {item.price * item.amount}
            {name == "name_en" ? "AED" : "دإ"}
          </p>
        </div>
      
          <p className="text-sm text-onBackground">{item.note}</p>
       
      </div>
    </div>
  );
};
const renderOptions = (e, i, name) => {
  // console.log(e);

  return (
    <div className="text-sm text-onBackground">
      <p key={i}>
        {e[name]}: {e.sub_option[name]}
        <Visibility visible={e.sub_option.price > 0}>
          (+{e.sub_option.price} {name == "name_en" ? "AED" : "دإ"})
        </Visibility>
      </p>
    </div>
  );
};

const DetilesLoading = () => (
  <div className="flex flex-col gap-1">
    <SheetHeader>
      <SheetTitle className="flex justify-center items-center">
        <Skeleton className={"w-48 h-5 "} />
      </SheetTitle>
    </SheetHeader>
    <div className="flex flex-col gap-4">
      <Skeleton className={"w-48 h-4"} />
      <Skeleton className={"w-48 h-4"} />
      <Skeleton className={"w-48 h-4"} />
      <Skeleton className={"w-48 h-4"} />
      <div className="flex gap-2">
        <Skeleton className={"w-20 h-20"} />
        <div className="flex flex-col gap-2">
          <Skeleton className={"w-48 h-4"} />
          <Skeleton className={"w-32 h-3"} />
        </div>
      </div>
    </div>
  </div>
);
