import React from "react";
import { indexStore } from "../../store/indexStore";
import { Route, Routes } from "react-router-dom";
import { FooterCopy } from "../footer";
import Restaurant from "../../resturant/ResturantName";
import Branch from "../../resturant/branch/Branch";
import ProductsTags from "../ProductsTags/ProductsTags";
import Menu from "../../resturant/branch/menus/Menu";
import Product from "../../resturant/branch/menus/product/Product";
import Cart from "../Cart/Cart";
import Orders from "../Cart/Orders";
import NavBottom from "../NavBottom";

function RestaurantRoutes() {
  const { lang } = indexStore();
  return (
    <div
      className={`relative flex flex-col min-h-screen justify-between ${
        lang === "ar" ? "font-almarai" : lang === "en" ? "font-Maven_Pro" : ""
      } `}
    >
      <Routes>
        <Route path="/:domain" Component={Restaurant} />
        <Route path="/:domain/:branch_id" Component={Branch} />
        <Route path="/:domain/:branch_id/:menu" Component={Menu} />
        <Route path="/:domain/:branch_id/cart" Component={Cart} />
        <Route path="/:domain/:branch_id/orders" Component={Orders} />
        <Route path="/:domain/:branch_id/popular" Component={ProductsTags} />
        <Route path="/:domain/:branch_id/new" Component={ProductsTags} />
        <Route path="/:domain/:branch_id/offers" Component={ProductsTags} />
        <Route path="/:domain/:branch_id/:menu/:product" Component={Product} />
      </Routes>
      {/* <FooterCopy /> */}
    </div>
  );
}

export default RestaurantRoutes;
