import React, { useEffect, useState } from "react";
import Tabs from "../../../components/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import Spacer from "../../../components/Spacer";
import { Header } from "../../../components/header";
import { indexStore } from "../../../store/indexStore";
import Visibility from "../../../components/utils/Visibility";
import IsNotActive from "../../../components/IsNotActive/IsNotActive";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import RenderAndLoadingMenuCilrcles from "../../../components/LoadingComponent/RenderAndLoadingMenuCilrcles";
import ProductsListMenu from "../../../components/ProductsListMenu/ProductsListMenu";
import NavBottom from "../../../components/NavBottom";
function Menu() {
  const {
    changeCategory,
    categoryId,
    fetchCategoriesAction,
    fetchProductsAction,
    products,
    menus,
    categories,
    selectProductAction,
    Menuloading,
    fetchMenusAction,
    loading,
    categoryLoading,
    fetchPriceOffersProducts,
  } = indexStore();

  const params = useParams();
  const paramsRequst = {
    domain: params.domain,
    branch_id: params.branch_id,
    menu_id: params.menu,
  };

  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState(params.menu);

  const onChange = (index, id) => {
    const p = {
      ...paramsRequst,
      category_id: id,
    };
    changeCategory(id);
    fetchProductsAction(p);
  };

  useEffect(() => {
    if (menus.length === 0) {
      fetchMenusAction(paramsRequst).then(() => {
        fetchPriceOffersProducts(params.branch_id);
      });
    }
    fetchCategoriesAction(paramsRequst)
      .then((res) => {
        if (categoryId === 0) {
          changeCategory(res[0].id);
          const p = {
            ...paramsRequst,
            category_id: res[0].id,
          };

          fetchProductsAction(p);
        }
      })
      .catch((error) => {
        // Set loading to false in case of an error
      });

    // Scroll to the active element if it exists and menus are not empty
    if (menus.length !== 0) {
      const activeElement = document.querySelector(".active");
      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest", // Prevents vertical scrolling
        });
      }
    }
    //   setSelectedMenu(menus.filter((e, i) => e.id == selectedMenu)[0]);
  }, [selectedMenu, menus]);

  /////////////////////////////////////////////////////////////////
  const handleSelectChange = (id) => {
    changeCategory(0);
    setSelectedMenu(id);
    navigate(`/${params.domain}/${params.branch_id}/${id}`);
  };

  const handelOnClickProduct = (item) => {
    navigate(`${item.id}`);
    selectProductAction(item);
  };

  return (
    <IsNotActive>
      <title>{paramsRequst.domain.toUpperCase()}</title>
      <Header url={`/${params.domain}/${params.branch_id}`} />
      <div className="bg-background flex-grow  p-4">
        <RenderAndLoadingMenuCilrcles
          loading={loading}
          menus={menus}
          selectedMenu={selectedMenu}
          handleSelectChange={(e) => handleSelectChange(e)}
        >
          <Spacer height={15} />
          <Visibility visible={!Menuloading}>
            <Tabs
              data={categories}
              value={categoryId}
              onChange={(index, id) => onChange(index, id)}
            />
            <ProductsListMenu
              products={products}
              handelOnClickProduct={handelOnClickProduct}
              loading={categoryLoading}
            />
          </Visibility>
          <Visibility visible={Menuloading}>
            <LoadingComponent width={75} height={35} count={3} gap={10} />
          </Visibility>
        </RenderAndLoadingMenuCilrcles>
      </div>
    </IsNotActive>
  );
}

export default Menu;
