import { useNavigate, useParams, Link } from "react-router-dom";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import Spacer from "./Spacer";
import Visibility from "./utils/Visibility";
import { indexStore } from "../store/indexStore";
import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { Skeleton } from "./ui/skeleton";

export const Header = (props) => {
  const [scroll, setScroll] = useState(false);
  const { name, restaurant, loading, 
    // lang, changeLangBottomSheet
   } =
    indexStore();
  // const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    window.addEventListener("scroll", () => {});
    const handleScroll = () => {
      setScroll(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const qantity = JSON.parse(
  //   localStorage.getItem("cart" + params.branch_id)
  // )?.reduce((acc, item) => acc + item.amount, 0);
  // const tokenOrder = localStorage.getItem("tokenOrder" + params.branch_id);

  return (
    <div
      className={`flex items-center w-full p-4 bg-background shadow-md z-10 sticky top-0 ${
        scroll ? "scroll bg-background" : ""
      } ${
        props.backgroundColorfull
          ? "bg-primary text-onPrimary shadow-none "
          : ""
      }`}
    >
      {/* Back Button (Left side) */}
      <Visibility visible={!props.noBack}>
        <button
          className={`text-lg cursor-pointer text-onBackground ${
            props.backgroundColorfull ? "bg-primary text-onPrimary" : ""
          }`}
          onClick={() => {
            navigate(props.url || -1);
          }}
        >
          {localStorage.getItem("Lang") === "ar" ? (
            <FaAngleRight />
          ) : (
            <FaAngleLeft />
          )}
        </button>
      </Visibility>

      {/* Centered Link (Middle element) */}
      <Visibility visible={!loading}>
        <Link
          className={`text-primary font-bold text-xl flex-grow uppercase tracking-widest text-center ${
            props.backgroundColorfull ? "!text-onPrimary" : ""
          }`}
          to={`/${params.domain}/${params.branch_id}`}
        >
          {restaurant[name]}
        </Link>
      </Visibility>

      <Visibility visible={loading}>
        <div className="flex flex-grow items-center justify-center">
          <Skeleton className="w-1/2 h-8 " />
        </div>
      </Visibility>

      <Visibility visible={props.noBack}>
        <Spacer width={30} />
      </Visibility>
      {/* <SideSheet backgroundColorfull={props.backgroundColorfull} /> */}
    </div>
  );
};