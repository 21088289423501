import * as React from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useTranslation } from "react-i18next";

export function DarkModeTogle() {
  const { setTheme } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col  gap-2 items-center  cursor-pointer">
      <Sun
        onClick={() => setTheme("dark")}
        className="  cursor-pointerh text-xl font-semibold rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0 "
      />
      <Moon
        onClick={() => setTheme("light")}
        className="  cursor-pointer absolute  text-lg font-semibold rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
      />

      <span className="text-sm font-semibold">{t("gloabl.theme")}</span>
    </div>
  );
}
