import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IsNotActive from "../components/IsNotActive/IsNotActive";
import { indexStore } from "../store/indexStore";
import { Header } from "../components/header";
import Visibility from "../components/utils/Visibility";
import { Skeleton } from "../components/ui/skeleton";
import Iterator from "../components/utils/Iterator";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { LocateFixed, LocateIcon } from "lucide-react";
import { CiLocationOn } from "react-icons/ci";
import NavBottom from "../components/NavBottom";

function Restaurant() {
  // const navigate = useNavigate();
  const { domain } = useParams();

  const {
    name,
    fetchBranchesAction,
    branchesLoading,
    branches,
    social,
    description,
    restaurant,
  } = indexStore();
  useEffect(() => {
    fetchBranchesAction({ domain: domain });
  }, []);
  const naivgate = useNavigate();
  const { t } = useTranslation();
  const onClickBranch = (e) => {
    naivgate(`${e}`);
  };
  return (
    <IsNotActive>
      <title>{domain.toUpperCase()}</title>
      <Header noBack={true} />
      <Visibility visible={!branchesLoading}>
        <section
          className={`bg-background p-4 flex  flex-col gap-5 flex-grow text-onBackground `}
        >
          <div className="flex flex-col gap-4 justify-between ">
            <p className="text-3xl font-bold">
              {t("gloabl.welcome")}

              {restaurant[name]?.toUpperCase()}
            </p>
            <p className="text-2xl font-bold">{t("gloabl.ourBranches")}:</p>
          </div>
          <div className="flex flex-col gap-5 pb-20">
            <Iterator
              data={branches}
              render={(branch) => (
                <BranchCard
                  branch={branch}
                  onClick={onClickBranch}
                  name={name}
                  description={description}
                />
              )}
            />
          </div>
        </section>
      </Visibility>
      <Visibility visible={branchesLoading}>
        <RenderLoading />
      </Visibility>
    </IsNotActive>
  );
}

export default Restaurant;

const RenderLoading = () => {
  return (
    <div>
      <Skeleton />
    </div>
  );
};

const BranchCard = ({ branch, onClick, name, description }) => {
  return (
    <div
      key={branch.id}
      className="flex flex-col gap-3 p-5 shadow-md border rounded-sm cursor-pointer justify-between "
      onClick={() => {
        onClick(branch.id);
      }}
    >
      <p className="text-2xl font-bold">{branch[name]}</p>
      <p className="text-lg font-bold">{branch[description]}</p>
      <p className="text-xl font-semibold flex gap-2 items-center">
        <CiLocationOn />
        {branch[name === "name_ar" ? "address_ar" : "address_en"]}
      </p>
      <Visibility visible={branch.phone}>
        <div className="flex gap-2 items-center">
          <MdOutlinePhoneInTalk />{" "}
          <p dir="ltr" className="">
            {branch.phone}
          </p>
        </div>
      </Visibility>
      <Visibility visible={branch.mobile}>
        <div className="flex gap-2 items-center">
          <IoPhonePortraitOutline />
          <p dir="ltr" className="">
            {branch.mobile}
          </p>
        </div>
      </Visibility>
    </div>
  );
};
