import OptSend from "./OptSend";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import orderServices from "../../api-service/order-service";
import Visibility from "../utils/Visibility";

export const BrowseProducts = ({ params, t }) => {
  return (
    <Link to={`/${params.domain}/${params.branch_id}`}>
      <button className=" bg-primary text-onPrimary px-4 py-2 rounded-lg hover:bg-primary-dark transition">
        {t("gloabl.browseProducts")}
      </button>
    </Link>
  );
};

export const CheckoutComponent = ({
  cartItems,
  total,
  t,
  params,
  loadCart,
}) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [openOptSheet, setOpenOptSheet] = useState(false);
  const tokenOrder = localStorage.getItem("tokenOrder" + params.branch_id);
  const items = cartItems.map((item) => ({
    product_id: item.id,
    amount: item.amount,
    sub_option_id: item.option
      ? item.option.map((opt) => opt.subOption.id)
      : [],
    note: item.note || "",
  }));
  const navigate = useNavigate();

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10,15}$/;
    return phoneRegex.test(phone);
  };
  const handleCheckout = async () => {
    if (!tokenOrder) {
      if (!name || !phone) {
        toast.error(t("gloabl.phoneOrName"));
        return;
      }
      if (!isValidPhoneNumber(phone)) {
        toast.error(t("gloabl.phoneInvalid"));
        return;
      }
      try {
        const otp = await orderServices
          .requestOtp({ phone: phone })
          .then((otp) => {
            setOpenOptSheet(true);
          });
      } catch (error) {
        toast.error(t("gloabl.orderError"));
      }
    } else {
      try {
        orderServices
          .storeOrder({
            token: tokenOrder,
            items: items,
            note: note,
            table_id: 1,
          })
          .then((res) => {
            toast.success(
              `${t("gloabl.orderSuccess")} # ${res.data.data.order_id}`,
              {
                onClick: () => {
                  navigate(`/${params.domain}/${params.branch_id}/orders`);
                },
              }
            );
            setName("");
            setPhone("");
            setNote("");
            localStorage.removeItem(`cart${params.branch_id}`);
            loadCart();
          })
          .catch((err) => toast.error(t("gloabl.orderError")));
      } catch (error) {
        toast.error(t("gloabl.orderError"));
      }
    }
  };
  return (
    <div className="flex flex-col gap-4 mt-4">
      <Visibility visible={!tokenOrder}>
        <input
          type="text"
          placeholder={t("contact.name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <input
          type="tel"
          placeholder={t("contact.phone")}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
        />
      </Visibility>

      <textarea
        placeholder={t("gloabl.addNote")}
        rows="3"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary resize-none"
      ></textarea>
      <div className="flex gap-2 ">
        <p className="text-onBackground font-semibold text-lg">
          {t("gloabl.totalPrice")}
        </p>
        <p className="text-primary text-lg font-bold">{total} AED</p>
      </div>
      <button
        onClick={() => handleCheckout()}
        type="button"
        className="text-onPrimary bg-primary rounded-xl hover:bg-primary/90 focus:ring-4 focus:outline-none focus:ring-primary/50 font-medium text-sm px-5 py-2.5 text-center"
      >
        {t("gloabl.checkout")}
      </button>
      <OptSend
        openOptSheet={openOptSheet}
        setOpenOptSheet={setOpenOptSheet}
        phone={phone}
        setPhone={setPhone}
        setName={setName}
        setNote={setNote}
        loadCart={loadCart}
        name={name}
        note={note}
        cartItems={items}
        branch_id={params.branch_id}
      />
    </div>
  );
};
